import React from "react";
import Skeleton from "react-loading-skeleton";

const GridProdSkeleton = () => {
  return (
    <div className="gridProductos">
      <div className="row sk-categorias2">
          <div className="col-12 col-xs-6 col-md-6 col-lg-6 d-flex">
            <Skeleton className="sk-cat" />
          </div>
          <div className="col-12 col-xs-6 col-md-6 col-lg-6 d-flex">
            <Skeleton className="sk-cat" />
          </div>
          <div className="col-12 col-xs-6 col-md-6 col-lg-6 d-flex">
            <Skeleton className="sk-cat" />
          </div>
          <div className="col-12 col-xs-6 col-md-6 col-lg-6 d-flex">
            <Skeleton className="sk-cat" />
          </div>
          <div className="col-12 col-xs-6 col-md-6 col-lg-6 d-flex">
            <Skeleton className="sk-cat" />
          </div>
          <div className="col-12 col-xs-6 col-md-6 col-lg-6 d-flex">
            <Skeleton className="sk-cat" />
          </div>
        </div>
    </div>
  );
};

export default GridProdSkeleton;
