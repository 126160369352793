import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const CalendarItem = (props) => {
  const {
    item,
    hourFormat,
    hourUnit,
    onClickItem,
    isMultiCity,
    isMultiHour,
    isCalendarListItemView,
  } = props;
  const { t } = useTranslation();
  let date = moment(item.Fecha);
  return (
    <article
      className={
        'clear-fl hover-text-ppal ' +
        (item.Agotado ? 'agotado ' : '') +
        (item.WaitList ? 'wait' : '')
      }
      onClick={!item.Agotado && item.isClickleable ? onClickItem : undefined}
    >
      <div className="dir" style={{ textTransform: 'capitalize' }}>
        {isMultiCity ? (
          <>
            <p id="pCiudadNombre" style={{ fontWeight: 'bold' }}>
              {item.CiudadNombre}{' '}
            </p>
            <span id="spanRecintoNombre" className="color-texto-contenido">
              {item.RecintoNombre}
            </span>
          </>
        ) : !item.isClickleable ? (
          <p>{date.format('dddd DD MMMM YYYY')}</p>
        ) : isMultiHour ? (
          <p>
            {moment
              .utc(date)
              .format(hourFormat === '12hr' ? 'hh:mm A' : 'HH:mm')}
            {hourFormat === '12hr' ? '' : ` ${hourUnit}`}
          </p>
        ) : (
          <p>{date.format('dddd DD MMMM YYYY')}</p>
        )}
      </div>
      <div className="fecha">
        {isMultiCity ? (
          <>
            <p id="pFecha">{date.format('dddd DD MMMM YYYY')}</p>
            <span id="secondL" className="color-texto-contenido">
              {moment
                .utc(date)
                .format(hourFormat === '12hr' ? 'hh:mm A' : 'HH:mm')}
              {hourFormat === '12hr' ? '' : ` ${hourUnit}`}
            </span>
          </>
        ) : !isCalendarListItemView ? (
          <p id="pHora">
            {moment
              .utc(date)
              .format(hourFormat === '12hr' ? 'hh:mm A' : 'HH:mm')}
            {hourFormat === '12hr' ? '' : ` ${hourUnit}`}
          </p>
        ) : null}
      </div>
      <div id="labelSelect" className="label">
        {item.Agotado ? t('soldOut').toUpperCase() : item.Extra}
      </div>
      {item.WaitList ? <div className="label wait">{t('waitList')}</div> : null}
    </article>
  );
};

export default CalendarItem;
