import React, { useState, useEffect } from "react";
import CustomerForm from "../../../components/Form";
import formsJson from "../../../forms.json";
import PayMethods from "./../PayMethods";
import {
    URL_BASE_2,
    currencyFormatDE,
    urlEvent,
    createCookie,
} from "../../../utils/globals";
import Services from "../../../services/Services";
let validacionLUKA = false;
const PayDonation = (props) => {
    const { global, t, organization } = props;
    const [selectedPay, setSelectedPay] = useState("");
    const [startedPay, setStartedPay] = useState(false);
    const [detalleRegistro, setDetalleRegistro] = useState(0);
    const [formValid, setFormValid] = useState(false);
    const [checkTerms, setCheckTerms] = useState(false);
    const [modalTerms, setModalTerms] = useState(false);
    const [errorTerms, setErrorTerms] = useState(false);
    const [tokenLuka, setTokenLuka] = useState("");
    const [email, setEmail] = useState("");
    const [initWebPay, setInitWebPay] = useState({});
    const [validCounter, setValidCounter] = useState(0);
    const [selectedBank, setSelectedBank] = useState(0);
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        repeatEmail: "",
        phoneNumber: "",
        region: "",
        address: "",
        city: "",
        zipCode: "",
    });
    const [paymentFailure, setPaymentFailure] = useState(false);
    let forms = formsJson[global.InformacionComprador - 1]
    .rows;
        if(global.InformacionComprador === "4" && props.moneda.toLowerCase() === "clp"){
            forms = forms.map((arr)=>{
                return arr.filter(n=>n.name!=="country")
            })
        }
        else if(global.InformacionComprador === "4" && props.moneda.toLowerCase() !== "clp"){
            forms = forms.map((arr)=>{
                return arr.filter(n=>n.name!=="region")
            })
        }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        validacionLUKA = formValid;
    }, [formValid]);
    useEffect(() => {
        if (email) {
            (async () => {
                await updateEmailRegisterDetail();
            })();
        }
    }, [email]);
    useEffect(() => {
        const init = async () => {
            const {
                status,
                data,
            } = await Services.pay.initRegisterDetailSimple({
                CanalId: Number(global.Canalid),
                OrganizacionDonacionId: organization.Id,
                MontoDonacion: props.totalMount,
                MonedaAlterna: props.moneda,
            });
            if (status === 200) {
                setDetalleRegistro(data);
                setCheckTerms(!global.CheckTeminosCondiciones);
            }
        };
        init();
    }, []);

    const validateForm = (values, valid) => {
        setFormValid(valid);
        if (valid) {
            setFormData(values);
            setEmail(values.email);
        }
    };

    const validateFromLuka = () => {
        if (!validacionLUKA) {
            setValidCounter((count) => count + 1);
        }
        return validacionLUKA;
    };

    const validateTerms = () => {
        const { global } = props;
        setErrorTerms(global.CheckTeminosCondiciones && !checkTerms);
    };

    const updateEmailRegisterDetail = async () => {
        const body = [
            {
                op: "replace",
                path: "/Email",
                value: email,
            },
            {
                op: "replace",
                path: "/Telefono",
                value: formData.phoneNumber,
            },
            {
                op: "replace",
                path: "/Ciudad",
                value: formData.city,
            },
            {
                op: "replace",
                path: "/Region",
                value: formData.region,
            },
            {
                op: "replace",
                path: "/ZipCode",
                value: formData.zipCode,
            },
            {
                op: "replace",
                path: "/Direccion",
                value: formData.address,
            },
        ];

        try {
            const { status } = await Services.pay.updateEmailRegisterDetail(
                detalleRegistro.Id,
                body
            );
            console.log('updateEmailRegisterDetail333')
            if (status === 200) {
                if (selectedPay === "LUKA" && tokenLuka !== "")
                    window.luka.updateEmail(email, tokenLuka);
            } else {
                console.log(status);
            }
        } catch (error) {
            console.log("error", error);
        }
    };
    const handleModalTerms = () => setModalTerms((modal) => !modal);
    const handlePay = async () => {
        setStartedPay(true);
        if (selectedPay === "WEBPAY") {
            const nombreCompleto = `${formData.firstName} ${formData.lastName}`;
            const urlFinal = `${URL_BASE_2}/transbank/NotificacionPagoIframe?drid=${detalleRegistro.Id}&Nombredelconsumidor=${nombreCompleto}&page=${window.location.href}`;
            setInitWebPay({
                detalleregistroid: detalleRegistro.Id,
                urlretorno: urlEvent(window.location.href),
                urlfinal: urlFinal,
                IniciarPago: true,
                page: urlEvent(window.location.href),
            });
            setStartedPay(true);
        } else if (selectedPay === "KHIPU") {
            try {
                const body = {
                    DetalleRegistroId: detalleRegistro.Id,
                    Bank: selectedBank,
                    EventoNombre: organization.Nombre,
                    ReturnUrl: window.location.href,
                    CancelUrl: window.location.href,
                    Nombre: formData.firstName,
                    Apellido: formData.lastName,
                    Email: formData.email,
                };
                const response = await Services.pay.initPayKhipu(body);
                if (response.status === 200) {
                    if (response.data.Result) {
                        createCookie(
                            "idKhipu",
                            response.data.Payment.payment_id,
                            1
                        );
                        window.KhipuLib.startKhipu(
                            response.data.Payment.payment_url,
                            response.data.Payment.payment_id,
                            response.data.Payment.ready_for_terminal
                        );
                        setTimeout(window.KhipuLib.sendToKhipu, 10000);
                    } else {
                        setStartedPay(false);
                    }
                } else {
                    // console.log(response.status);
                    setStartedPay(false);
                }
            } catch (error) {
                // console.log(error);
                setStartedPay(false);
            }
        }
    };
    return (
        <section id="wrap-ppal" className="wrap-donation">
            <div id="wrap-cont">
                <div className="container-custom-tm clear-fl">
                    <div id="wrap-pago" className="wrap-pago donation clear-fl">
                        <div>
                            <section className="content">
                                <div className="title-g sub-title-bold color-titulos d-flex align-items-center">
                                    <i
                                        className="icon-back size-25 pr-30 pointer"
                                        onClick={() => {
                                            //window.location.reload(true);
                                            props.setPayDonation(false);
                                        }}
                                    ></i>
                                    <span>{t("userInfo")}</span>
                                </div>
                                <CustomerForm
                                    rowsFields={
                                        forms
                                    }
                                    validCounter={validCounter}
                                    validateForm={validateForm}
                                    subCultura={organization.SubCultura}
                                />
                            </section>
                        </div>
                        <section className="resumen resumen-donation">
                            <div className="resume-pay-mobile">
                                <div className="title-g sub-title-bold color-titulos">
                                    {global.Idioma.toLowerCase() === "es" && (
                                        <span>
                                            {organization &&
                                            organization.labelPayButton
                                                ? organization.labelPayButton
                                                : t("yourDonation")}
                                        </span>
                                    )}
                                    {global.Idioma.toLowerCase() === "en" && (
                                        <span>
                                            {organization &&
                                            organization.labelPayButton_en
                                                ? organization.labelPayButton_en
                                                : t("yourDonation")}
                                        </span>
                                    )}
                                </div>
                                <div className="wrap-resumen con-total">
                                    <div className="don">
                                        <div className="title clear-fl mb-30 titulo-donacion d-flex">
                                            <span className="color-titulos d-flex flex-grow-1">
                                                {t("type")}
                                            </span>
                                            <span className="d-flex flex-grow-1 justify-content-end">
                                                {t("subTotal").toUpperCase()}
                                            </span>
                                        </div>
                                        <article className="clear-fl">
                                            <p className="clear-fl">
                                                {global.Idioma.toLowerCase() ===
                                                    "es" && (
                                                    <span>
                                                        {organization &&
                                                        organization.labelCart
                                                            ? organization.labelCart
                                                            : t("donate")}
                                                    </span>
                                                )}
                                                {global.Idioma.toLowerCase() ===
                                                    "en" && (
                                                    <span>
                                                        {organization &&
                                                        organization.laberCart_en
                                                            ? organization.laberCart_en
                                                            : t("donate")}
                                                    </span>
                                                )}
                                                <span>
                                                    {`${
                                                        props.moneda === "EUR"
                                                            ? organization.MonedaSimboloOpcion2
                                                            : organization.MonedaSimbolo
                                                    } ${currencyFormatDE(
                                                        props.totalMount,
                                                        global.thousandsSeparator,
                                                        global.decimalSeparator,
                                                        global.MostrarDecimales
                                                    )}`}
                                                </span>
                                                <i
                                                    className="icon-cerrar entrada"
                                                    onClick={() => {
                                                        window.location.reload(
                                                            true
                                                        );
                                                    }}
                                                ></i>
                                            </p>
                                        </article>
                                        <article className="clear-fl"></article>
                                    </div>
                                    <div id="total" className="bg-secundario">
                                        <p className="clear-fl">
                                            {t("total")}
                                            <span>{`${
                                                props.moneda === "EUR"
                                                    ? organization.MonedaSimboloOpcion2
                                                    : organization.MonedaSimbolo
                                            } ${currencyFormatDE(
                                                props.totalMount,
                                                global.thousandsSeparator,
                                                global.decimalSeparator,
                                                global.MostrarDecimales
                                            )}`}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <PayMethods
                            selectedOption={organization}
                            moneda={props.moneda}
                            paisId={props.paisId}
                            comercioId={props.comercioId}
                            global={global}
                            t={t}
                            totalMount={props.totalMount}
                            setSelectedPay={setSelectedPay}
                            detalleRegistro={detalleRegistro}
                            validateFromLuka={validateFromLuka}
                            setTokenLuka={setTokenLuka}
                            checkTerms={checkTerms}
                            errorTerms={errorTerms}
                            handleCheck={setCheckTerms}
                            handleModalTerms={handleModalTerms}
                            initPay={initWebPay}
                            bank={setSelectedBank}
                            formData={formData}
                            paymentFailure={setPaymentFailure}
                        />
                        {selectedPay !== "" &&
                        selectedPay !== "PAYU" &&
                        selectedPay !== "LUKA" ? (
                            <button
                                onClick={() => {
                                    validateTerms();
                                    setValidCounter((count) => count + 1);
                                    if (
                                        formValid &&
                                        checkTerms &&
                                        selectedPay !== ""
                                    ) {
                                        handlePay();
                                    }
                                }}
                                className={`btn-skin btn-pago btn-ppal active`}
                                id="btn-pago"
                            >
                                {startedPay ? (
                                    <div
                                        className="loading inside"
                                        style={{ padding: "15px" }}
                                    >
                                        <div></div>
                                    </div>
                                ) : (
                                    <>
                                        <img
                                            src="/images/pago/lock.svg"
                                            alt="lock"
                                        />
                                        {`${t("donate")} ${
                                            props.moneda === "EUR"
                                                ? organization.MonedaSimboloOpcion2
                                                : organization.MonedaSimbolo
                                        }${currencyFormatDE(
                                            props.totalMount,
                                            global.thousandsSeparator,
                                            global.decimalSeparator,
                                            global.MostrarDecimales
                                        )}`}
                                    </>
                                )}
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>
            <div
                className={modalTerms ? "modal fade in" : "modal fade"}
                id="modalTerminos"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="modalTerminos"
                aria-hidden="true"
                style={{ display: modalTerms ? "block" : "none" }}
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="conten-terms clear-fl">
                            <div
                                className="cerrar"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleModalTerms}
                            >
                                <i className="icon-cerrar-simple"></i>
                            </div>
                            <div className="title sub-title-bold color-titulos">
                                {t("termsAndConditions").toUpperCase()}
                            </div>
                            <div className="wrap-terms">
                                <div
                                    className="text color-texto-contenido"
                                    style={{ overflowX: "hidden" }}
                                >
                                    <p style={{ whiteSpace: "pre-line" }}>
                                        {global.TextoTeminosCondiciones.replace(
                                            /<br>/gm,
                                            "\n"
                                        ).replace(/\/\*\//gm, "\n")}
                                    </p>
                                </div>
                            </div>
                            <a
                                className="btn-skin btn-modal-term btn-ppal active"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    handleModalTerms();
                                    if (selectedPay === "LUKA") {
                                        window.luka.terminosOk();
                                    } else {
                                        setCheckTerms(true);
                                    }
                                }}
                            >
                                {t("agree")}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={
                    paymentFailure
                        ? "modal fade modalAlert in"
                        : "modal fade modalAlert"
                }
                style={{ display: paymentFailure ? "block" : "none" }}
                id="modalFailure"
                role="dialog"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-md"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="conten-expired clear-fl">
                            <div className="top">
                                <i className="icon-cerrar color-b"></i>
                            </div>
                            <div className="content">
                                <div className="title color-titulos">
                                    {t("paymentError")}
                                </div>
                                <p className="color-texto-contenido">
                                    {t("paymentErrorMsg")}
                                </p>

                                <p className="color-texto-contenido">
                                    {t("tryAgain").toUpperCase()}
                                </p>
                                <a
                                    href=""
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setPaymentFailure(false);
                                        window.location.reload();
                                    }}
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    className="color-ppal hover-text-ppal"
                                >
                                    {t("labelOK").toUpperCase()}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PayDonation;
