import React, { useEffect, useState } from "react";
import Categorias from "./pages/categorias";
import "./assets/styles/global.scss";
import ListItems from "../ListItems";
import { useDispatch, useSelector } from "react-redux";
import ResumePay from "../Purchase/ResumePay";
import { useTranslation } from "react-i18next";
import { currencyFormatDE, currencyFormatValue } from "../../../utils/globals";
import CarTop from "./components/car/carTop";
import { setTotalMount } from "../../../actions/event";
import { ShoppingCart, NewBack } from "../../../utils/iconsSvg";
import DetailCar from "./components/car/DetailCar";

const Index = ({ goPurchase, back }) => {
  const global = useSelector((store) => store.global);
  const carProds = useSelector((store) => store.car.productos);
  const { t } = useTranslation();
  const { resumeTickets, selectedFunction } = useSelector(
    (store) => store.event
  );
  const [TotalEnt, setTotalEnt] = useState(0);
  const [ShowCarMovil, setShowCarMovil] = useState(false);
  const [preloader, setPreloader] = useState(false);
  const [Monto, setMonto] = useState(0);
  const [MontoTienda, setMontoTienda] = useState(0);
  const dispatch = useDispatch();
  const Simbolo =
    global.MonedaPorDefecto === "USD" && selectedFunction.Iso === "VES"
      ? "$"
      : selectedFunction.MonedaSimbolo;
  useEffect(() => {
    let tEnt = 0;
    let monto = 0;
    resumeTickets.map((t) => {
      let fee = getFee(t);
      tEnt += t.cantidadSel;
      if (global.mostrarCargos) {
        monto =
          monto +
          (selectedFunction.Iso === "VES" && global.MonedaPorDefecto === "USD"
            ? currencyFormatValue(
                global.dolarToday,
                t.Precio,
                global.MostrarDecimales
              ) + fee
            : t.Precio + fee) *
            t.cantidadSel;
      } else {
        monto =
          monto +
          (selectedFunction.Iso === "VES"
            ? global.MonedaPorDefecto === "USD"
              ? currencyFormatValue(
                  global.dolarToday,
                  t.Precio,
                  global.MostrarDecimales
                ) + fee
              : t.Precio + fee
            : t.Precio + fee) *
            t.cantidadSel;
      }
    });
    // console.log("montooooooooooooooooooooo", monto);
    setMonto(monto);
    setTotalEnt(tEnt);
  }, []);

  useEffect(() => {
    let montoT = 0;
  }, [carProds]);

  useEffect(() => {
    console.log('monto',Monto,MontoTienda)
  }, [Monto,MontoTienda]);

  const getFee = (t) => {
    console.log('getFee',t,selectedFunction.TipoDesglose)
    return (t.EsPorcentual
      ? calculateFee(
          selectedFunction.Iso === "VES" && global.MonedaPorDefecto === "USD"
            ? currencyFormatValue(
                global.dolarToday,
                t.Precio,
                global.MostrarDecimales
              )
            : t.Precio,
          t.Valor,
          global.MostrarDecimales
        )
      : selectedFunction.Iso === "VES" && global.MonedaPorDefecto === "USD"
      ? currencyFormatValue(global.dolarToday, t.Valor, global.MostrarDecimales)
      : t.Valor);
  };

  const buttonClick = () => {
    setPreloader(true);
    goPurchase ()
  };
  
  let MontoIVA = 0;
  const calculateFee = (price, percentage, mostrarDecimales = true) =>
    mostrarDecimales
      ? (price * percentage) / 100
      : Math.floor((price * percentage) / 100);

      let txtfreee = t("free");
  return (
    <section id="wrap-tienda" className="container-custom-tm">
      <div className="wrap-grid">
        <div className="">
          <div className="title-g sub-title-bold color-titulos mb-5">
            <span data-toggle="modal">{t("adds")}</span>
          </div>
          <Categorias langs={t}/>
        </div>
        <div className="">
          <section className="resumen-tienda">
            <div className="">
              <div className="title-g sub-title-bold color-titulos mb-5">
                <span data-toggle="modal" data-target="#modalFailure">
                  {t("orderSummary")}
                </span>
              </div>
              <div className="wrap-resumen con-total">
                <div className="tickets show tickets-g">
                  <a href="#" className="ver-t clear-fl">
                    <p className="color-titulos">
                      <b>{t("tickets")} </b>

                      <span>({TotalEnt})</span>
                    </p>
                    <p></p>
                  </a>
                  <div className="title clear-fl" style={{ display: "flex" }}>
                    <span
                      style={{
                        display: "block",
                        width: "40%",
                        textAlign: "left",
                      }}
                    >
                      {" "}
                      {t("type").toUpperCase()}
                    </span>
                    <span
                      style={{
                        display: "block",
                        width: "20%",
                        textAlign: "right",
                      }}
                    >
                      {t("qtyAbr").toUpperCase()}
                    </span>
                    <span
                      style={{
                        display: "block",
                        width: "40%",
                        textAlign: "right",
                      }}
                    >
                      {t("subTotal").toUpperCase()}
                    </span>
                  </div>
                  
                            {resumeTickets && (
                                <article>
                                    {resumeTickets.length > 0
                                        ? resumeTickets.map((t, i) => {
                                              return (
                                                  <div
                                                      className="clear-fl"
                                                      style={{
                                                          display: "flex",
                                                          position: "relative",
                                                          marginBottom: "15px",
                                                      }}
                                                      key={i}
                                                  >
                                                      <p
                                                          style={{
                                                              width: "40%",
                                                              textAlign: "left",
                                                          }}
                                                      >
                                                          <span></span>
                                                          <span>
                                                              {
                                                                  t.TipoEntradaNombre
                                                              }
                                                          </span>
                                                      </p>
                                                      <p
                                                          style={{
                                                              width: "20%",
                                                              textAlign:
                                                                  "right",
                                                          }}
                                                      >
                                                          {t.cantidadSel}
                                                      </p>
                                                      <p
                                                          style={{
                                                              width: "40%",
                                                              textAlign:
                                                                  "right",
                                                          }}
                                                      >
                                                        {
                                                          t.Precio === 0 ?
                                                            txtfreee
                                                          :
                                                          (
                                                            selectedFunction.Iso ===
                                                          "VES"
                                                              ? global.MonedaPorDefecto ===
                                                                "VES"
                                                                  ? `${Simbolo} ${
                                                                        global.mostrarCargos &&
                                                                        selectedFunction.TipoDesglose !==
                                                                            2
                                                                            ? currencyFormatDE(
                                                                                  (t.Precio +
                                                                                      getFee(
                                                                                          t
                                                                                      )) *
                                                                                      t.cantidadSel,
                                                                                  global.thousandsSeparator,
                                                                                  global.decimalSeparator,
                                                                                  global.MostrarDecimales
                                                                              )
                                                                            : currencyFormatDE(
                                                                                  (t.Precio +
                                                                                      t.Comision -
                                                                                      ((t.MontoIVA ||
                                                                                          0) +
                                                                                          (t.ComisionIVA ||
                                                                                              0))) *
                                                                                      t.cantidadSel,
                                                                                  global.thousandsSeparator,
                                                                                  global.decimalSeparator,
                                                                                  global.MostrarDecimales
                                                                              )
                                                                    }`
                                                                  : `$ ${
                                                                        (global.mostrarCargos && selectedFunction.TipoDesglose !== 2)
                                                                            ? currencyFormatDE(
                                                                                  (currencyFormatValue(
                                                                                      global.dolarToday,
                                                                                      t.Precio,
                                                                                      global.MostrarDecimales
                                                                                  ) +
                                                                                      getFee(
                                                                                          t
                                                                                      )) *
                                                                                      t.cantidadSel,
                                                                                  global.thousandsSeparator,
                                                                                  global.decimalSeparator,
                                                                                  global.MostrarDecimales
                                                                              )
                                                                            :(currencyFormatDE(
                                                                                  currencyFormatValue(
                                                                                      global.dolarToday,
                                                                                      (t.Precio +
                                                                                          t.Comision -
                                                                                          ((t.MontoIVA ||
                                                                                              0) +
                                                                                              (t.ComisionIVA ||
                                                                                                  0))) *
                                                                                          t.cantidadSel,
                                                                                      global.MostrarDecimales
                                                                                  ),
                                                                                  global.thousandsSeparator,
                                                                                  global.decimalSeparator,
                                                                                  global.MostrarDecimales
                                                                              ))
                                                                    }`
                                                              : `${Simbolo} ${
                                                                    global.mostrarCargos
                                                                        ? currencyFormatDE(
                                                                              (t.Precio +
                                                                                  getFee(
                                                                                      t
                                                                                  )) *
                                                                                  t.cantidadSel,
                                                                              global.thousandsSeparator,
                                                                              global.decimalSeparator,
                                                                              global.MostrarDecimales
                                                                          )
                                                                        : currencyFormatDE(
                                                                              (t.Precio +
                                                                                  getFee(
                                                                                      t
                                                                                  )) *
                                                                                  t.cantidadSel,
                                                                              global.thousandsSeparator,
                                                                              global.decimalSeparator,
                                                                              global.MostrarDecimales
                                                                          )
                                                                }`
                                                          )
                                                        }
                                                        
                                                          
                                                      </p>
                                                  </div>
                                              );
                                          })
                                        : null}
                                </article>
                            )}
                </div>
                        
                <div className="car" style={{ padding: "16px 26px 16px" }}>
                  <a href="#" className="ver-t clear-fl">
                    {carProds.length > 0 && (
                      <p className="color-titulos">
                        <b>{t("adds")}</b>
                      </p>
                    )}
                    <DetailCar setMontoTienda={setMontoTienda} setPreloader={setPreloader}/>
                  </a>
                </div>
                
                <div id="total" className="bg-secundario">
                  <p className="clear-fl">
                    {t("totalOrder")}
                    {
                      Monto + MontoTienda === 0 ?  <span>{txtfreee}</span> :
                      <span>
                        {selectedFunction.Iso === "VES"
                          ? global.MonedaPorDefecto === "USD"
                            ? global.MonedaPorDefecto
                            : Simbolo
                          : Simbolo}{" "}
                        {currencyFormatDE(
                          Monto + MontoTienda,
                          global.thousandsSeparator,
                          global.decimalSeparator,
                          global.MostrarDecimales
                        )}
                      </span>
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className="notaProd">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 41 40"
              >
                <defs>
                  <clipPath id="clip-Artboard_1">
                    <rect width="35" height="35"></rect>
                  </clipPath>
                </defs>
                <g
                  id="Artboard_1"
                  data-name="Artboard – 1"
                  clipPath="url(#clip-Artboard_1)"
                >
                  <g
                    id="Group_3202"
                    data-name="Group 3202"
                    transform="translate(-1121.989 -119.583)"
                  >
                    <path
                      id="Path_3516"
                      data-name="Path 3516"
                      d="M161.623,258.641a12.755,12.755,0,0,0-5.6-2.132,13,13,0,0,0-1.585-.1h0a12.739,12.739,0,0,0-11.823,8.033,13.379,13.379,0,0,0-.509,1.588,12.735,12.735,0,0,0-.382,3.1c0,.21.006.42.016.627a12.39,12.39,0,0,0,.178,1.585,12.66,12.66,0,0,0,2.718,5.881,13.21,13.21,0,0,0,1.6,1.617,12.7,12.7,0,0,0,8.2,3.007c.4,0,.8-.019,1.187-.057a12.713,12.713,0,0,0,6-23.146Zm-7.189,21.612a11.108,11.108,0,0,1-8.2-3.618,11.26,11.26,0,0,1-1.6-2.256,11.047,11.047,0,0,1-1.1-3.039,10.836,10.836,0,0,1-.2-1.585c-.013-.207-.019-.417-.019-.627a11.048,11.048,0,0,1,.439-3.1,11.308,11.308,0,0,1,.6-1.588A11.144,11.144,0,0,1,154.434,258h0a11.322,11.322,0,0,1,1.585.111,11.127,11.127,0,0,1-1.588,22.141Z"
                      transform="translate(988.053 -129.545)"
                      fill="var(--c1)"
                    ></path>
                    <path
                      id="Path_3517"
                      data-name="Path 3517"
                      d="M179.97,279.995a.75.75,0,0,1-.8-.7v-9.267a.8.8,0,0,1,1.591,0V279.3A.75.75,0,0,1,179.97,279.995Z"
                      transform="translate(962.519 -138.357)"
                      fill="var(--c1)"
                    ></path>
                    <path
                      id="Path_3518"
                      data-name="Path 3518"
                      d="M179.97,310.38a.8.8,0,0,1-.8-.8v-1.731a.8.8,0,0,1,1.591,0v1.731A.8.8,0,0,1,179.97,310.38Z"
                      transform="translate(962.519 -164.073)"
                      fill="var(--c1)"
                    ></path>
                  </g>
                </g>
              </svg>
              <span>
                {t("sumaryDisclaimer")}
              </span>
            </div>
            {
              preloader === true ?
              <button className="btn-ppal btn-skin active">
                <div className="spinner-main spinner3">
                  <div className="loadingio-spinner-rolling-k94er4xvtw spinner3-b">
                    <div className="ldio-arie1i327iv spinner3-c">
                      <div
                        style={{
                          border: `10px`,
                          borderStyle: "solid",
                          borderColor: `#ffffff`,
                          borderTopColor: "transparent",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </button>
            :
              <button className="btn-ppal btn-skin active" onClick={buttonClick}>
                {carProds.length === 0 ? t("ContinueWithoutAdd") : t("continue")}
              </button>
            }
          </section>
        </div>
        <div className="resume-tienda-movil">
          <div
            className={`wrap-resumen con-total ${ShowCarMovil ? "active" : ""}`}
          >
            <div className="tickets show tickets-g">
              <a href="#" className="ver-t clear-fl">
                <p className="color-titulos">
                  <b>{t("tickets")} </b>

                  <span>({TotalEnt})</span>
                </p>
                <p></p>
              </a>
              <div className="title clear-fl" style={{ display: "flex" }}>
                <span
                  style={{
                    display: "block",
                    width: "40%",
                    textAlign: "left",
                  }}
                >
                  {" "}
                  {t("type").toUpperCase()}
                </span>
                <span
                  style={{
                    display: "block",
                    width: "20%",
                    textAlign: "right",
                  }}
                >
                  {t("qtyAbr").toUpperCase()}
                </span>
                <span
                  style={{
                    display: "block",
                    width: "40%",
                    textAlign: "right",
                  }}
                >
                  {t("subTotal").toUpperCase()}
                </span>
              </div>
              {resumeTickets && (
                <article>
                  {resumeTickets.length > 0
                    ? resumeTickets.map((t, i) => {
                        return (
                          <div
                            className="clear-fl"
                            style={{
                              display: "flex",
                              position: "relative",
                              marginBottom: "15px",
                            }}
                            key={i}
                          >
                            <p
                              style={{
                                width: "40%",
                                textAlign: "left",
                              }}
                            >
                              <span></span>
                              <span>{t.TipoEntradaNombre}</span>
                            </p>
                            <p
                              style={{
                                width: "20%",
                                textAlign: "right",
                              }}
                            >
                              {t.cantidadSel}
                            </p>
                            <p
                              style={{
                                width: "40%",
                                textAlign: "right",
                              }}
                            >
                              {selectedFunction.Iso === "VES"
                                ? global.MonedaPorDefecto === "VES"
                                  ? `${Simbolo} ${
                                      global.mostrarCargos &&
                                      selectedFunction.TipoDesglose !== 2
                                        ? currencyFormatDE(
                                            (t.Precio + getFee(t)) *
                                              t.cantidadSel,
                                            global.thousandsSeparator,
                                            global.decimalSeparator,
                                            global.MostrarDecimales
                                          )
                                        : currencyFormatDE(
                                            (t.Precio +
                                              t.Comision -
                                              ((t.MontoIVA || 0) +
                                                (t.ComisionIVA || 0))) *
                                              t.cantidadSel,
                                            global.thousandsSeparator,
                                            global.decimalSeparator,
                                            global.MostrarDecimales
                                          )
                                    }`
                                  : `$ ${
                                      global.mostrarCargos &&
                                      selectedFunction.TipoDesglose !== 2
                                        ? currencyFormatDE(
                                            (currencyFormatValue(
                                              global.dolarToday,
                                              t.Precio,
                                              global.MostrarDecimales
                                            ) +
                                              getFee(t)) *
                                              t.cantidadSel,
                                            global.thousandsSeparator,
                                            global.decimalSeparator,
                                            global.MostrarDecimales
                                          )
                                        : currencyFormatDE(
                                            currencyFormatValue(
                                              global.dolarToday,
                                              (t.Precio +
                                                t.Comision -
                                                ((t.MontoIVA || 0) +
                                                  (t.ComisionIVA || 0))) *
                                                t.cantidadSel,
                                              global.MostrarDecimales
                                            ),
                                            global.thousandsSeparator,
                                            global.decimalSeparator,
                                            global.MostrarDecimales
                                          )
                                    }`
                                : `${Simbolo} ${
                                    global.mostrarCargos
                                      ? currencyFormatDE(
                                          (t.Precio + getFee(t)) *
                                            t.cantidadSel,
                                          global.thousandsSeparator,
                                          global.decimalSeparator,
                                          global.MostrarDecimales
                                        )
                                      : currencyFormatDE(
                                          (t.Precio + getFee(t)) *
                                            t.cantidadSel,
                                          global.thousandsSeparator,
                                          global.decimalSeparator,
                                          global.MostrarDecimales
                                        )
                                  }`}
                            </p>
                          </div>
                        );
                      })
                    : null}
                </article>
              )}
            </div>
            {carProds.length > 0 && (
              <div className="car" style={{ padding: "16px 26px 16px" }}>
                <a href="#" className="ver-t clear-fl">
                  <p className="color-titulos">
                    <b>{t("adds")}</b>
                  </p>
                  <DetailCar setMontoTienda={setMontoTienda} setPreloader={setPreloader}/>
                </a>
              </div>
            )}
          </div>
          <div className="footer">
            <div
              className={`button-car`}
              onClick={() => setShowCarMovil(!ShowCarMovil)}
            >
              <span style={{width:'32px',height: '32px',alignItems: 'center', justifyContent: 'center', display: 'flex',position:'relative'}}>
                    <ShoppingCart />
                    <span style={{
                      position: 'absolute',
                      color: `rgb(255, 255, 255)`,
                      borderRadius: '100%',
                      background: `var(--c1)`,
                      width: `18px`,
                      height: `18px`,
                      border: `none`,
                      top: `-9px`,
                      display: `flex`,
                      alignItems: `center`,
                      justifyContent: `center`,
                      right: `-9px`,
                      fontWeight: `500`,
                      fontSize: `15px`
                    }}>
                      {TotalEnt}
                    </span>
                  </span>
              {/*<img src="/images/icono.svg" alt="" />*/}
              <span>
                {selectedFunction.Iso === "VES"
                  ? global.MonedaPorDefecto === "USD"
                    ? global.MonedaPorDefecto
                    : Simbolo
                  : Simbolo}
                {currencyFormatDE(
                  Monto + MontoTienda,
                  global.thousandsSeparator,
                  global.decimalSeparator,
                  global.MostrarDecimales
                )}
              </span>
            </div>
            {
              preloader === true ?
              <button className="btn-ppal btn-skin active">
                <div className="spinner-main spinner3">
                  <div className="loadingio-spinner-rolling-k94er4xvtw spinner3-b">
                    <div className="ldio-arie1i327iv spinner3-c">
                      <div
                        style={{
                          border: `10px`,
                          borderStyle: "solid",
                          borderColor: `#ffffff`,
                          borderTopColor: "transparent",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </button>
            :
              <button className="btn-ppal btn-skin active" onClick={buttonClick}>
                {carProds.length === 0 ? t("ContinueWithoutAdd") : t("continue")}
              </button>
            }
          </div>
        </div>
      </div>
    </section>
  );
};

export default Index;
